@import url(assets/css/style.css);
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~@ng-select/ng-select/themes/default.theme.css";



/* Utility Functions */
.of-v {
  overflow-x: visible !important;
  overflow-y: visible !important;
}

ngb-modal-window {
  z-index: 1060 !important;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.ck.ck-content ul,
.ck.ck-content ul li {
    list-style-type: inherit;
}

.ck.ck-content ol,
.ck.ck-content ol li {
    list-style-type: decimal;
}


.ck.ck-content ul {
    padding-left: 40px; /* Adjust this value as needed */
}

.ck.ck-content ol {
  padding-left: 40px; /* Adjust this value as needed */
}

.ck.ck-content em {
  font-style: italic !important;
}

.ck.ck-content i {
  font-style: italic !important;
}

.custom-accordion .mat-expansion-panel-header .mat-expansion-indicator {
  display: none; /* Hide the default arrow */
}


.p-paginator-page.p-highlight {
  background-color: #007bff !important; /* Strong blue background for the active page */
  color: white !important; /* White text for active page */
  font-weight: bold !important; /* Bold text for better visibility */
  border: 1px solid #007bff !important; /* Blue border */
}

.p-paginator-page {
  color: #333 !important; /* Dark text for non-active pages */
  font-weight: normal !important;
  border-radius: 0.25rem;
  padding: 0.5rem !important;
  border: 1px solid #d1d3db !important; /* Grey border for non-active pages */
}

.p-paginator-page:not(.p-highlight) {
  background-color: transparent !important;
  color: #6c757d !important; /* Lighter grey text for non-active pages */
}




