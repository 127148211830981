//Tree

.tree-ngx {
}

.node {
}

.node-children {
}

.node-name {
  display: inline-block;
  &.markSelected {
  }

  .active {
    cursor: pointer;
  }
}

.node-offset {
  display: flex;
}

.node-icon-wrapper {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 18px;
  top: 1px;
  left: 6px;

  &.disabled {
    cursor: default;
  }
}

.collapsable {
  cursor: pointer;
}

.node-container {
  display: inline-block; //Empty container to align nodes with no collapse
}
