.node-name {
  display: inline-block;
}
.node-name .active {
  cursor: pointer;
}

.node-offset {
  display: flex;
}

.node-icon-wrapper {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 18px;
  top: 1px;
  left: 6px;
}
.node-icon-wrapper.disabled {
  cursor: default;
}

.collapsable {
  cursor: pointer;
}

.node-container {
  display: inline-block;
}

.node-checkbox {
  display: inline-block;
  position: relative;
  top: 3px;
  left: 5px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.node-checkbox:disabled {
  cursor: auto;
}

.collapsible-wrapper {
  display: flex;
  overflow: hidden;
}

.collapsible-wrapper:after {
  content: "";
  transition: height 0.3s linear, max-height 0s 0.3s linear;
  max-height: 0px;
}

.collapsible {
  transition: margin-bottom 0.3s cubic-bezier(0, 0, 0, 1);
  margin-bottom: 0;
  max-height: 1000000px;
}

.collapsible-wrapper.collapsed > .collapsible {
  margin-bottom: -20000px;
  transition: margin-bottom 0.3s cubic-bezier(1, 0, 1, 1), visibility 0s 0.3s, max-height 0s 0.3s;
  visibility: hidden;
  max-height: 0;
}

.collapsible-wrapper.collapsed:after {
  height: 0;
  transition: height 0.3s linear;
  max-height: 25px;
}

.arrow-down {
  position: absolute;
  width: 0;
  height: 0;
  left: 8px;
  top: 4px;
  width: 20px;
  height: 19px;
}

.arrow-right {
  position: absolute;
  width: 0;
  height: 0;
  left: 8px;
  top: 4px;
  width: 20px;
  height: 19px;
}