//Checkbox

.node-checkbox {
  display: inline-block;
  position: relative;
  top: 3px;
  left: 5px;
  width: 16px;
  height: 16px;
  cursor: pointer;

  &:disabled {
    cursor: auto
  }
}
