@charset "UTF-8";
/****************************/
/** Imports **/
/****************************/
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,600,600i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab&display=swap" rel="stylesheet');
@import url(elements.css);
@import url(layout.css);
@import url(https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css);

/****************************/
/** Reset **/
/****************************/
body { color: #43425D; background: #F0F0F7 !important; font-family: 'Source Sans Pro', sans-serif !important; font-size: 15px; font-style: normal; line-height: normal; }
html, body{ height:100% !important; font-family: 'Source Sans Pro', sans-serif; }
html, body, div, span, applet, object, iframe, h1, h2, h3, .error-comment, h4, h5, h6, .panel-title, p, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; font-size: 100%; font: inherit; vertical-align: baseline; }
strong { font-weight: bold; }
p,ul{margin:0; padding:0;}
a:hover, a:active { outline: 0; }
button:focus { border: none; outline: 0; }
ul { list-style: none; }
.force-UTF-8 { content: "¡"; }
dt { font-weight: 600; }
code { background: transparent; }

::-webkit-file-upload-button {
 cursor: pointer;
}
.wrapper { padding-left: 64px; -webkit-transition: 0.4s ease; -moz-transition: 0.4s ease; transition: 0.4s ease; }
/* Top navbar */
.top-bar { background: #fff; height: 71px; padding-left: 64px; -webkit-transition: 0.4s ease; -moz-transition: 0.4s ease; transition: 0.4s ease; }
.top-bar .navbar { height: 71px; }
.top-bar .navbar li.user-auth { border-left: #EBEBF2 1px solid; padding: 0 14px; }
.top-bar .navbar li.user-auth .name { margin-right: 15px; margin-left: 10px; color: #4D4F5C; }
.top-bar .navbar li.user-auth a:hover:hover{ text-decoration:none;}
.top-bar .navbar li.user-auth .name .down-arrow { background: url(/assets/images/up-arrow-offgray.svg) no-repeat; width: 11px; height: 7px; display: inline-block; margin-left: 16px; }
.top-bar .navbar li.user-auth.show a span .down-arrow { transform: rotate(180deg); }
.top-bar .navbar li.user-auth > ul{ border-radius:0px; padding: 10px 0; border:0px;  min-width: 200px; right: 0px; position: absolute; left: auto; box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);}
.top-bar .navbar li.user-auth > ul > li{ padding:0px; margin:0px; }
.top-bar .navbar li.user-auth > ul > li > a{  padding:5px 15px; color:#43425D; width:100%; display:block;}
.top-bar .navbar li.user-auth > ul > li:hover > a{ text-decoration:none; background:#F5F6FA; }
.top-bar .navbar li.user-auth .user-auth-img { height: 38px; width: 38px; }
.top-bar .navbar li:not(.user-auth) { width: 22px; height: 22px; margin: 0 14px; text-align: center; }
.top-bar .navbar li:not(.user-auth) a { display: inline-block; }
.top-bar .navbar li.support a { background: url(/assets/images/support-icon.svg) no-repeat; width: 16px; height: 16px; }
.top-bar .navbar li.chat a { background: url(/assets/images/chat-icon.svg) no-repeat; width: 16px; height: 16px; }
.top-bar .navbar li.notification a { background: url(/assets/images/notification%20Icon.svg) no-repeat; width: 19px; height: 21px; }
/* dropdown-menu*/
.top-bar .navbar li ul.dropdown-menu li { width: auto; height: auto; text-align: left; }
/*subnavigation*/
.subnavigation .navbar-title { font-size: 15px; color: #fff; font-weight: bold; margin: 0 19px; position:relative;}
.subnavigation .navbar { background: #4A5A69; height: 54px;}
.subnavigation .navbar .navbar-nav{ flex-wrap:wrap;}
.subnavigation .navbar .nav-item a { color:#A5ADB4; font-weight:600; text-transform:uppercase; text-decoration: underline; padding: 0px 0px 3px 0px; font-size: 14px;       display: flex;   align-items: center; white-space: pre;  margin: 0 34px 0 0;}
.subnavigation .navbar .nav-item.active a {color: #fff; font-weight: bold; text-decoration:none;}
.subnavigation .navbar .nav-item .circle{background:#A5ADB4; height:14px; min-width:14px; border-radius:50%; display:inline-block; margin:0 9px 0 0;}

.subnavigation .navbar .nav-item.active .circle { background: #3597EC url(/assets/images/ellipses-icon.svg) no-repeat center !important; background-size: 67% !important; }
.subnavigation .navbar .nav-item.incomplete .circle { background: #F7941E url(/assets/images/incomplete-icon.svg) no-repeat center; background-size: 15%; }
.subnavigation .navbar .nav-item.completed .circle { background: #00A89B url(/assets/images/tick-white-mark-icon.svg) no-repeat center; background-size: 61%; }



/*substep-nav*/
.substep-nav { height: 129px; background: #fff; box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16); padding: 15px 20px; }
.substep-nav h2.substep-title { font-size: 23px; font-weight: 700; color: #4D4F5C; margin-bottom: 15px; }
.substep-nav .progress { overflow: visible; background: #ECECEC; height: 10px;}
.substep-nav .progress .progress-bar { position: relative; background: transparent; }
.substep-nav .progress .progress-bar.inprogress .title { cursor: initial; font-weight: bold; text-decoration: none; }
.substep-nav .progress .progress-bar .title { cursor: pointer; position: absolute; color: #4D4F5C; font-size: 18px; top: 22px; left: 0px; text-decoration: underline; }
.substep-nav .progress .progress-bar:after { content: ""; width: 4px; height: 4px; border-radius: 4px; background: #3596ec; position: absolute; }
.substep-nav .progress .progress-bar .percentage { height: 10px; }
.substep-nav .progress .progress-bar:first-child .percentage { border-radius: 10px 0 0 10px; }
.substep-nav .progress .progress-bar:last-child .percentage { border-radius: 0 10px 10px 0; }
/*stages*/
.substep-nav .progress .progress-bar.inprogress .percentage { background: #3597EC }
.substep-nav .progress .progress-bar.incompleted .percentage { background: #F7941E }
.substep-nav .progress .progress-bar.completed .percentage { background: #00A89B }
.substep-nav .progress .progress-bar.completed:after { width: 16px; height: 16px; border: 2px solid #4D4F5C; background: #fff; border-radius: 16px; right: -8px; z-index: 1; }
.substep-nav .progress .progress-bar:after { content: ""; width: 4px; height: 4px; border-radius: 4px; background: #3596ec; position: absolute; right: -2px; }
.substep-nav .progress .progress-bar:last-child:after{ display:none;}
/*fixed leftbar*/

.fixed-sidebar-left .nav-header { height: 71px; -webkit-transition: 0.4s ease; -moz-transition: 0.4s ease; transition: 0.4s ease; overflow: hidden; background: #1C2A39; }
.fixed-sidebar-left .nav-header .toggle-btn { display: inline-block; width: 27px; height: 17px; background: url(/assets/images/toggle-left-slide.svg) no-repeat; margin-top: -3px; }
.slide-nav-toggle .fixed-sidebar-left .nav-header .toggle-btn { background: url(/assets/images/toggle-right-slide.svg) no-repeat; }
.slide-nav-toggle .logo-wrap { display: none; }
.fixed-sidebar-left { position: fixed; top: 0px; left: 0; width: 235px; margin-left: 0; bottom: 0; z-index: 100; -webkit-transition: all 0.4s ease; -moz-transition: all 0.4s ease; transition: all 0.4s ease; }
.slide-nav-toggle .navbar.navbar-inverse.navbar-fixed-top .nav-header { width: 64px; }
.slide-nav-toggle .navbar.navbar-inverse.navbar-fixed-top .nav-header .logo-wrap .brand-text { opacity: 0; visibility: hidden; }
.slide-nav-toggle.sidebar-hover .navbar.navbar-inverse.navbar-fixed-top .nav-header { width: 225px; }
.slide-nav-toggle.sidebar-hover .navbar.navbar-inverse.navbar-fixed-top .nav-header .logo-wrap .brand-text { opacity: 1; visibility: visible; }
.slide-nav-toggle .fixed-sidebar-left { width: 64px; }
.slide-nav-toggle .fixed-sidebar-left .side-nav li{width: 100%;}
.slide-nav-toggle .fixed-sidebar-left .side-nav li.navigation-header > span { display: none; }
.slide-nav-toggle .fixed-sidebar-left .side-nav li.navigation-header > i { display: block; }
.slide-nav-toggle .fixed-sidebar-left .collapse.in { display: none; }
.slide-nav-toggle .fixed-sidebar-left:hover .side-nav li.navigation-header > span { display: block; }
.slide-nav-toggle .fixed-sidebar-left:hover .side-nav li.navigation-header > i { display: none; }
.slide-nav-toggle .fixed-sidebar-left:hover .collapse.in { display: block; }
/*.slide-nav-toggle .fixed-sidebar-left:hover .right-nav-text { opacity: 1; }*/
.slide-nav-toggle .fixed-sidebar-left .right-nav-text { display: none; }
/* fixed sidebar */


/*navigation*/
.fixed-sidebar-left{background:#323e4a; padding-bottom:80px;}
.fixed-sidebar-left .right-nav-text { display: block; -webkit-transition: opacity 0.2s ease 0s; -moz-transition: opacity 0.2s ease 0s; transition: opacity 0.2s ease 0s; flex-grow: 2; font-size: 18px; line-height: 18px; padding: 4px 0 3px 35px; }
.fixed-sidebar-left .side-nav { border: none; height: 100%; width: 100%; position: relative; border-radius: 0; margin: 0; backface-visibility: hidden; padding: 10px 0; }
.fixed-sidebar-left .side-nav > li { width: 235px; position: relative; transition: all 0.4s ease; white-space: nowrap; }
.fixed-sidebar-left .side-nav > li > a .icon { width: 22px; height: 27px; position: absolute; background-size: 100%; }
.fixed-sidebar-left .side-nav > li > a .caret-down{width:9px; height:6px; margin:11px 0 0 0; right: 20px;}
.fixed-sidebar-left .side-nav > li > a { color: #B0BAC4; padding: 14px 15px; min-height:53px; display: flex; width: 100%; -webkit-transition: 0.3s ease; -moz-transition: 0.3s ease; transition: 0.3s ease; border-left: transparent solid 5px;  }
.fixed-sidebar-left .side-nav > li > a:hover{ text-decoration:none; background:#415164;}
.fixed-sidebar-left .side-nav > li > a.active { border-left: 5px solid #3597EC; background-color:#29343E; }

/*subnavigation*/
.fixed-sidebar-left .side-nav li > ul{background:#29343E; padding:1px 0 0px 73px; border-left: 5px solid #3597EC;}
.fixed-sidebar-left .side-nav > li > ul > li{width:auto; padding:9px 0px; }
.fixed-sidebar-left .side-nav > li > ul > li > a{font-size:15px; color:#B0BAC4; display:block;}
.fixed-sidebar-left .side-nav > li > ul > li > a.active{color: #fff;}
.fixed-sidebar-left .side-nav > li > a.active .icon.tickets { background-image: url(../images/ticket-icon-white.svg); }
.fixed-sidebar-left .side-nav > li > a.active .icon.configuration{background-image:url(../images/configuration-white.svg);}
.fixed-sidebar-left .side-nav > li > a.active .icon.site-setting{background-image:url(../images/site-setting-white.svg);}
.fixed-sidebar-left .side-nav > li > a.active .icon.page-collection{background-image:url(../images/page-collection-white.svg);}
.fixed-sidebar-left .side-nav > li > a.active .icon.dashboard { background-image: url(../images/dashboard-icon-white.svg); }
.fixed-sidebar-left .side-nav > li > a.active .icon.user{background-image:url(../images/user-icon-white.svg);}
.fixed-sidebar-left .side-nav > li > a.active .icon.master{background-image:url(../images/master-icon-white.svg);}
.fixed-sidebar-left .side-nav > li > a.active .icon.my-account{background-image:url(../images/account-white.svg);}
.fixed-sidebar-left .side-nav > li > a.active .icon.proposal{background-image:url(../images/proposal-white.svg);}
.fixed-sidebar-left .side-nav > li > a.active .icon.services{background-image:url(../images/services-icon-white.svg);}
.fixed-sidebar-left .side-nav > li > a.active .icon.websites { background-image: url(../images/manage-website-icon-white.svg); }
.fixed-sidebar-left .side-nav > li > a.active .icon.cp{ background-image: url(../images/cp-icon-white.svg); }
.fixed-sidebar-left .side-nav > li > a.active .icon.intake { background-image: url(../images/Intakes-icon-white.svg); }
.fixed-sidebar-left .side-nav > li > a.active .icon.connect{background-image:url(../images/connect-white.svg);}
.fixed-sidebar-left .side-nav > li > a.active .icon.report { background-image: url(../images/reports-icon-white.svg); }
.fixed-sidebar-left .side-nav > li > ul > li > a:hover{ text-decoration:none;}
.fixed-sidebar-left .side-nav > li > ul > li{position:relative;}
.fixed-sidebar-left .side-nav > li > ul > li.current:before{width:9px; content:""; height:9px; background:#00A89B; position:absolute; left:-20px; top:6px; border-radius:10px;}

.fixed-sidebar-left .side-nav > li > ul > li a:hover, .fixed-sidebar-left .side-nav > li > ul > li.current a, .fixed-sidebar-left .side-nav > li > ul > li.current a:hover{color:#fff; font-weight:700;}
.fixed-sidebar-left .side-nav > li > ul > li a:hover{font-weight:normal;}
.slide-nav-toggle .fixed-sidebar-left .side-nav li > ul{display:none;}



/*common content area*/
.content-area { padding: 0; }
.bg-white { background: #fff; box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16); }
/*wizard*/
/*wizard-leftsection*/
.wizard .left-navigation .head-section { padding: 20px; }
.wizard .left-navigation .head-section h2 { color: #4D4F5C; font-size: 21px; line-height: 21px; font-weight: bold; margin-bottom: 15px; }
.wizard .left-navigation {max-width: 210px; width:100%; margin:0 15px; }
.wizard .left-navigation .nav-tabs .nav-item .nav-link { font-size: 15px; border: 0px; padding: 14px 15px; min-height: 52px; border-left: 5px solid #ECECEC; font-weight: 600; font-style: italic; color: #C7C7C7; border-radius: 0px;  border-bottom: 1px solid #ECECEC; display:flex;   border-top: 1px solid #ECECEC; word-break: break-word;}

.wizard .left-navigation .nav-tabs .nav-item .nav-link .circle { background: #ECECEC; min-width: 22px; height: 22px; border-radius: 22px; vertical-align: middle; display: inline-block; margin: 0 10px 0 0; }
/*active cases*/
.wizard .left-navigation .nav-tabs .nav-item .nav-link.active { background: #F5F6FA; border-left-color:#4D4F5C;  }
.wizard .left-navigation .nav-tabs .nav-item .nav-link.active, .wizard .left-navigation .nav-tabs .nav-item .nav-link.incomplete, .wizard .left-navigation .nav-tabs .nav-item .nav-link.completed { font-weight: 700; font-style: normal; color: #4D4F5C; word-break: break-word;}
.wizard .left-navigation .nav-tabs .nav-item .nav-link.active .circle { background: #3597EC url(/assets/images/ellipses-icon.svg) no-repeat center !important; background-size: 50% !important; }
.wizard .left-navigation .nav-tabs .nav-item .nav-link.incomplete .circle { background: #F7941E url(/assets/images/incomplete-icon.svg) no-repeat center; background-size: 14%; }
.wizard .left-navigation .nav-tabs .nav-item .nav-link.completed .circle { background: #00A89B url(/assets/images/tick-white-mark-icon.svg) no-repeat center; background-size: 50%; }


.wizard .tags { padding: 20px; }
.wizard .tags h3 { font-size: 13px; font-weight: bold; color: #4D4F5C; margin-bottom: 10px; }
.wizard .tags ul li { font-size: 13px; color: #4D4F5C; padding: 5px 20px; position: relative; }
.wizard .tags ul li:before { content: ""; width: 10px; height: 10px; border-radius: 10px; position: absolute; left: 0px; top: 10px; }
.wizard .tags ul li.completed:before { background: #00A89B; }
.wizard .tags ul li.not-completed:before { background: #F7941E; }
.wizard .tags ul li.active:before { background: #3597EC; }
.wizard .tags ul li.not-started:before { background: #ECECEC; }
.wizard .tags ul li.not-started{color: #ccc;}

/*wizard-content*/
.wizard-content { margin: 0 15px; width: 100%; max-width:1200px; }
.wizard-content .content { padding:20px;}



.gray-bg{background:#F5F6FA}
.heading-main{ padding:32px 18px; border-left:12px solid #4D4F5C; margin-bottom:38px;}
.heading-main h2{ color:#4D4F5C; font-weight:bold; font-size:24px; margin-bottom:32px; line-height:19px; }
.heading-main p{ font-size:19px; color:#43425D; line-height:26px; margin-bottom:0px;}

.form{ padding:0;}
.form .form-field { margin-bottom:17px;}
.form .form-field p, .form p, .form label{ margin-bottom:18px; font-size:17px; color:#43425D; line-height:17px; }
.form label.checkbox-container.lg{font-size:17px; line-height:30px}
.form .form-field .aud-input{margin-bottom:12px;}



/*accordion section*/

.aud-accordion .panel-body .panel-row.custom-field .aud-input{ height:26px; }

.aud-accordion .panel-body .panel-row .disabled a.title{ color:#9A9A9D; text-decoration:none;}
.aud-accordion .panel-body .panel-row a.title{ font-size:17px;}


/*product accordion css */
.form-control.form-control-sm{height: 35px; padding: 5px 10px; font-size: 15px; color:#4D4F5C; border-radius:4px;}
.aud-accordion a.openall, .product-accordion a.closeall{color:#4296F7; font-size:15px; line-height:34px; text-decoration:underline;}

.aud-accordion .panel-heading .panel-title {background:#F5F6FA; border-bottom:#D3D3D3 solid 1px; padding:10px 18px;}
.aud-accordion .panel-heading .panel-title a{font-size:19px; font-weight:bold; color:#4D4F5C; text-decoration:none; position:relative;}
.aud-accordion .panel-heading .panel-title a.accordion-toggle:after{content:""; background:url(/assets/images/panel-arrow.svg) no-repeat 0 0; top:7px; right:-17px; position:absolute; width:8px; height:15px;transition:all 0.5s ease; display:inline-block}
.aud-accordion .panel-heading.activestate .panel-title a.accordion-toggle:after{transform:rotate(90deg);}


.aud-accordion .panel-body .panel-row{padding:8px 0; border-bottom:#D3D3D3 solid 1px; margin:0;}
.aud-accordion .panel-body .panel-row a{ margin-right:10px;}
.aud-accordion .panel-body .panel-row a:last-child{ margin-right:0px;}
.aud-accordion .panel-body .panel-row i.icon { width:19px; height:19px; margin-right:5px;}

/* content hading */
.heading-content h3, .style-section h3, .font-19-heading{font-size:19px; line-height:19px; color:#4D4F5C; font-weight:600; margin-bottom:30px;}
.heading-content h2{font-size:21px; line-height:21px; color:#43425D; font-weight:600; margin-bottom:32px;}
.heading-content p{ font-size:15px; color:#4D4F5C;}

/* overlay */
.overlay{background:#4D4F5C; height:100%; width:100%; left:0; top:0; position:absolute; opacity:0.6;}

/* content editor css */
.content-editor img{max-width:100%;}

/* accordion style2*/
.aud-accordion.style2 .panel-heading .panel-title{padding:17px 18px;}
.aud-accordion.style2 .panel-default{background:#fff; border:#D3D3D3 solid 1px;}

.aud-accordion.style2 .panel-heading .panel-title{ background:#fff; border:none;}
.aud-accordion.style2 .panel.disabled .panel-heading .panel-title a{color:#C7C7C7; font-style:italic; font-weight:600;}
.aud-accordion.style2 .panel-default.active{box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);}
.aud-accordion.style2 .panel .panel-body{padding:0 18px 17px;}
.aud-accordion.style2 .panel-body h2{font-size:19px; font-weight:700; margin-bottom:20px;}
.form-control.form-control-promotion{width:50px; height:28px; padding:0 6px;}


/* Branding Page */
.branding-page-mid-height{height:80px;}
.branding-color-picker .form-control.color-field{ border-radius:3px; box-shadow:0 0 0 3px #dadde2; color:#2699FB; font-size:14px;}
.branding-color-picker .form-control.color-field{background-size:45px 42px; background-position:99.3% center;}

/* branding style photography */
.subject-matter .form-field h4{ font-size:17px; margin-bottom:17px;}

/* branding style */
.style-section .form-field h5{font-size:15px; line-height:15px; font-weight:600; margin-bottom:18px;}
.style-section .form-field h4{font-size:17px; line-height:17px; font-weight:600; margin-bottom:18px;}
.style-section .form-field .styling-layout{max-width:190px;}


.subnavigation .navbar-title:after{content: ""; width: 10px;  height: 10px; background: #00A89B;  border-radius: 10px;  position: absolute;  left:50%;    top: -23px; transform:translate(-50%,0)}

.iconWidthHeight{
    height: 20px;
    width: 20px;
    background-size: 75%;
}

/*footer*/
footer{
    width:97%;
    bottom: 0px;
    padding: 10px 0;
}
/* .nicescroll-bar{overflow: hidden;} */
/* .nicescroll-bar:hover{overflow-y:auto } */
/* width */
::-webkit-scrollbar { width: 7px;}
  /* Track */
  ::-webkit-scrollbar-track {background: #f1f1f1; }
  /* Handle */
  ::-webkit-scrollbar-thumb {background: #888; }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {background: #555;}

  .selected-color{
    width: 50px;
    height: 50px;
    float: left;
    background: transparent;
    border: 3px solid #fff;
    margin-right:10px;
    box-shadow: 0px 0px 1px #bbb;
    position: relative;
  }

  .selected-color:after{
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 10px 10px;
    position: absolute;
    right: 0;
    bottom: 0;
    border-color: transparent transparent #ffffff transparent;
  }
  .disabled{
      pointer-events: none;
  }
  /*****for mandatory label*****/
.mandatory{position:relative; margin-left: 6px; margin-right: 1px;}
.mandatory:before{color:#ff0000; content:"*"; position:absolute; left:-6px; top:0px; font-size:14px;}
/*****for mandatory label*****/
h5.title, h6.title{font-weight:600; color:#4D4F5C; font-size:17px; margin-bottom:6px;}
h6.title{font-size:15px;}
/*****common seperator for repeating section*****/
.common-seperator{border-bottom:#e9e9e9 solid 4px; padding-bottom: 20px; margin-bottom: 25px; border-radius: 0px;}
/*****review page label and value*****/
.summary-list label{font-weight:700; margin-bottom:0;}
.summary-list ul li, .summary-list label+div{font-style:italic;}
.summary-list h3{font-size:18px; font-weight:700; color:#43425D;}
.show{
    display: block !important;
}
.loader{
    background: url(/assets/images/loader.gif) no-repeat center rgba(0,0,0,0.3);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 99999999;
    background-size: 15%;
  }

  .custom-file .form-control.aud-input.form-control-lg:disabled{
      opacity: 0;
  }

  .subString{
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    float: left;
    white-space: nowrap;
  }

  .toast-container .toast{
      opacity: 1 !important;
  }

  .inside-box.file{
      border: 2px solid transparent;
  }

  .inside-box.file.selected{
      border: 2px solid #2b9f77 !important;
  }

  .footer p{font-size:15px;}
  select.form-control.aud-input{padding-right:53px !important;}
  .affiliate-logo-modal, .affiliate-logo-modal .modal-dialog.modal-lg{display:flex !important;}
  .affiliate-logo-modal .modal-content{margin:auto; min-width:750px;}
  .affiliate-logo-modal .modal-content .inner-content{min-height:220px;}
  .affiliate-logo-modal .modal-content .red-cross{color:#b00000; font-size:24px;}
  .modal-content .card-body .img-wrap img{max-width:100%;}
  h5.bold-title, h6.bold-title{font-weight:700;}


  .addPlatform::after {
    content: ",";
  }
  .addPlatform:last-child::after{
      content: "";
  }
  .content-blur {
    background: rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
  }

/*  .nav-item.submitted:after{
    content: "";
    width: 10px;
    height: 10px;
    background: #00A89B;
    border-radius: 10px;
    position: absolute;
    left: 50%;
    top: -14px;
    transform: translate(-50%,0);
  }*/
.img-wrap img, .logo-captionCall img, .angular-editor .angular-editor-textarea img{max-width:100%;}
.card-header .btn-link, .card-header .btn-link:hover{text-decoration:none;}
    /***************fixed custome file uploader*******************/
  .custom-file .custom-file-label{left:auto !important; z-index:2  !important; margin: 0px; width:46px; position:absolute; right:0;}
  .style-heading{font-weight:600; margin-bottom:18px;}
  .style-section .p-style-2{margin-bottom:40px; font-size:17px; line-height:17px; color:#646379;}
  .bottom-btns{background:#323E4A; padding:24px; margin-top:38px; display: block; float: left; width: 100%;}

  .intake-step h5{font-weight:700; color:#4d4f5c; font-size:18px; margin-bottom:14px; line-height:18px;}
  .accordion .card-body .listItem{padding: 7px 0 7px 50px;}
  /****************landing page css**********************/

  .landing-page .boxes{border:#707070 solid 1px; padding:36px 15px; margin-bottom:42px;}
  .landing-page .boxes p{font-size:17px; line-height:23px; margin:0;}
  .landing-page .boxes p span{font-weight:600; text-decoration:underline;}
  .landing-page .boxes p a{color:#3597EC; font-weight:600; text-decoration:underline;}
  .heading-24{font-size:24px; font-weight:600; color:#4D4F5C; margin-bottom:33px; line-height:24px;}
  .landing-page .addition-info h6{color:#4a4c57; font-size:18px; margin-bottom:16px; line-height:24px; font-weight:600;}
  .landing-page .addition-info a{ text-decoration:underline;}
  .landing-page .addition-info p{color:#646379}

  /****************landing page css**********************/
  .tree-ngx{font-family: 'Source Sans Pro', sans-serif;}
.pages-practice-main .card-header-pages-practice.node-head{background: #F5F6FA;  padding: 0 18px; font-size: 17px;
    font-weight: bold; color: #4D4F5C; text-decoration: none; border-bottom: #D3D3D3 solid 1px; height:46px; line-height:46px;}
.node.pages-practice-main{border-bottom:none;   margin: 0;}
.node.pages-practice-main .listItem{margin:0; border:none; padding:0; font-size:15px; line-height:36px; height:36px;}
.pages-practice-main .collapsible-wrapper .collapsible{width:100%;}
.pages-practice-main .collapsible-wrapper .collapsible .pages-practice-main{ position:relative;}
.pages-practice-main .collapsible-wrapper .card-header-pages-practice{border-bottom:#D3D3D3 solid 1px; padding-left:46px;}
.pages-practice-main .checkbox-container.sm .checkmark{margin-top:3px;}
.pages-practice-main a.sample-link{position:absolute; right:0; top:0; font-size:15px; color:#4296F7; text-decoration:underline;}
.pages-practice-main .arrow-down{background: url(/assets/images/panel-arrow.svg) no-repeat center center; transform: rotate(90deg); transition:all 0.5s ease;}
.pages-practice-main .arrow-right{background: url(/assets/images/panel-arrow.svg) no-repeat center center;}
.card-body ul li.listItem{font-size:15px;}
.pages-practice-main .collapsible-wrapper .collapsible-wrapper > .collapsible > .saaa > .pages-practice-main .card-header-pages-practice{padding-left:76px;}
.pages-practice-main .collapsible-wrapper .collapsible-wrapper > .collapsible > .saaa > .pages-practice-main .collapsible-wrapper > .collapsible > .saaa > .pages-practice-main .card-header-pages-practice{padding-left:105px;}
.custom-boxes{background: #f5f5f5; border: 1px solid #e3e3e3; padding:3px; max-width:200px; max-height:200px; overflow:hidden; float: left;}
.custom-boxes img{max-width:100%; display:block; max-height:100%;}
.noData{
    width: 100%;
    height: 100%;
    border: 3px dashed #ddd;
    border-radius: 10px;
    min-height: 300px;
    text-align: center;
    font-size: 20px;
    line-height: 300px;
}
a.delete-btn{color:#c90505;}

.divider{height:1px; width:100%; border-bottom:#D3D3D3 solid 1px; padding:35px 0 0 0; margin-bottom:44px;}
.contact-info .person-detail{word-break: break-all;}
.heading-content h6{color:#676771; font-size:13px; text-transform:uppercase; font-weight:600; margin-bottom:19px;}

@media print {
    .fixed-sidebar-left, .top-bar {display: none;}
    .wrapper{padding: 0px;}
    #filter{
        display: none;
    }
 }
 .aud-wrap {
  /* white-space: pre-wrap;       */
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  /* word-break:break-all;    */
}

.glyphicon{
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}

.glyphicon-triangle-top:before {
    content: "\f077";
}

.glyphicon-triangle-bottom:before {
    content: "\f078";
}

.pointer{
    cursor: pointer;
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 25px;
    margin-bottom: 0px !important;
  }



  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: #2196F3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(34px);
    -ms-transform: translateX(34px);
    transform: translateX(34px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .no_wrap{
      white-space: nowrap;
  }
  .ui-datatable .ui-datatable-thead>tr>th{
   background: #F5F6FA;
    color: #4D4F5C;
    font-weight: 700;
  }

  .ui-datatable .ui-datatable-thead>tr>th, .ui-datatable .ui-datatable-tfoot>tr>td, .ui-datatable .ui-datatable-data>tr>td{
  padding: 10px;
    border-bottom: #D3D3D3 solid 1px;
    border-top: 0px;
    font-size: 14px;
    position: relative;
    }
  .pop-height{
    max-height: 500px;
    overflow-y: auto;
  }
  /****************************new css 22-4-19*************************************/
  .tooltip .tooltip-inner{background:#323E4A !important;  font-family: 'Source Sans Pro', sans-serif !important; padding:10px .5rem; opacity:1;}
  .bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before{border-top-color: #323E4A;}
  .filter-bar{margin-bottom:25px; width:100%;}
  .filter-bar label{font-weight:600; margin-bottom:6px; font-size:15px;}

  /*******************************tab style*****************************************/
 .nav-tabs.aud-tab-style .nav-item a.nav-link{    background: #F8F8F8;    font-size: 15px;    border: #E9E9F0 solid 1px;    color: #9e9dab;
       padding: 11px 18px;    margin: 0 2px; }
  .nav-tabs.aud-tab-style .nav-item a.active{   background: #3B86FF;   color: #fff;  border: #3B86FF solid 1px;}
  textarea.form-control {
    resize: auto;
}
/*******************************icon listing for dashboard*****************************************/
.m-dashboard .heading-style3{font-size:15px; margin-bottom:15px; line-height:15px; color:#4d4f5c;}
.m-dashboard  .listing.roi-performance{padding:40px 0 0; margin-top:30px; border-top:#d8d8d8 solid 1px;}
.m-dashboard .listing .listing-box{width:100%; display:flex; text-align:center; border:#dadada solid 1px; min-height:238px; padding:20px; flex-direction: column;}
.m-dashboard .listing .listing-box img{align-self: center; margin-bottom:10px;}
.m-dashboard .listing .listing-box label{font-size:19px; color: #212529;}
a.pointer:hover{text-decoration: none;}
.m-dashboard .listing .listing-box h2{color:#3b86ff; font-size:45px;}
.set-select{margin-bottom:30px; width: 100%;  float: left;}
.set-select .que-select button{border: 1px solid #E8E9EC; border-radius: 4px; height:38px; font-size:15px !important;}
.set-select .que-select ul.available-items li{font-size:15px !important; padding:3px 5px !important;}
.set-select .que-select .ngx-dropdown-list-container{position:absolute !important; z-index:99;}
.image-wrap{
  background: #f5f5f5; border: 1px solid #e3e3e3; padding:3px; max-width:200px; max-height:200px ; overflow:hidden; float: left;
}
.image-wrap img{max-width:100%; display:block; max-height:100%;}
.italic-div{font-style: italic; padding-left: 4px;}

/* .cke_top, .cke_bottom{
  background: #333 !important;
} */

.tips{
  margin-top: 10px;
    display: block;
    text-decoration: underline;
    color: #3597EC;
    font-weight: bold;
}
/* .exclamation{} */
.icon.exclamation-icon{width:40px; height:40px; background-image: url(../images/exclaimnation-mark-icon.png);}
.slide-nav-toggle .side-nav > li > a .caret-down{
  display: none;
}

.pagination{
  margin: 10px 0px;
}

/* .slide-nav-toggle ul.collapse{
  position: absolute;
    top: 100%;
    left: 100%;
    padding-left: 10px !important;
    max-height: 350px;
    overflow: auto;
    overflow-x: hidden;
}

.slide-nav-toggle ul.collapse li{
  width: 200px !important;
    padding: 10px;
}



.slide-nav-toggle .fixed-sidebar-left .side-nav li:hover ul.collapse{
  display: block;
}

.slide-nav-toggle .fixed-sidebar-left .side-nav li:hover .right-nav-text{
  background: #29343E;
  left: 100%;
  position: absolute;
  padding: 18px 15px;
  font-weight: bold;
  top: 0;
  width: 217px;
  display: block;
}

.disableLink{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
} */

.ui-widget, .ui-widget *{
  text-overflow: ellipsis;
  overflow: hidden;
}

.subrow td{
  background: #f5fcff;
}

.subSection{
  -webkit-transition: all 0.5s; /* Safari prior 6.1 */
  transition: all 0.5s;
}

/**********tab panel start****************/


.flex-basis-one-third {
  flex-basis: 32% !important;
  max-width: 32%;
}

.graph-section .graph-box { padding: 25px; border: #C6C6C6 solid 1px; flex-basis: 32%; min-height: 290px; }
.box-size{padding: 20px; border: #C6C6C6 solid 1px;}
.graph-section .graph-box h4 { font-size: 24px; color: #4D4F5C; line-height: 24px; border-bottom: #A3A6B7 solid 1px; padding: 0 0 15px 0; margin-bottom: 20px; }

.wrapper.menuExpanded, .top-bar.menuExpanded{
  padding-left: 235px;
  -webkit-transition: 0.4s ease; -moz-transition: 0.4s ease; transition: 0.4s ease;
}

/**************************New Dashboard Style************************************/

body{font-family: 'Source Sans Pro', sans-serif; font-size: 13px; background:#F4F4F7; color:#303E4E; font-style: normal; line-height: normal; font-weight: 400;  line-height:normal;}
.bg-white{background:#fff; border:#D2D2D7 solid 1px;}
.dashboard-wrap{padding:20px 0;}
.listing{margin-bottom:27px;}
.list-content{padding:15px 20px; border:#D2D2D7 solid 1px;}
.white-bg{background:#fff;}
.flex-260{flex-basis:260px; min-width:260px;}
.listing-heading{background:#303E4E;  color: #fff;}
.listing-heading.xs h2{font-size:26px;   line-height:54px; height:57px;   font-weight:400; padding:0 20px; margin:0;}
.listing-heading.sm h3{font-size:17px;   line-height:35px;   padding:0 12px; font-weight:400;  margin:0;}
.sm-list-box{border:#C6C6C6 solid 1px;}
a.blue-link{font-size:15px; color:#4A9AF7; text-decoration:underline;}
.account-manager-thumb{width:76px; height:76px;}
.account-manager img{border-radius:50%; border:#DCE0EB solid 4px; height: 76px; width: 76px;}
.account-manager .contant-info p{margin-bottom:6px;}
.service-box{ margin: 5px 0px;}
.service-box .icon-box{height:87px;}
.account-manager-info h6{font-size:20px; line-height:26px; margin-bottom:5px;}
.account-manager-info p{font-size:13px; line-height:17px; font-style:italic;}
.contant-info h5{font-size:15px; font-weight:700;}
.notification-text span{font-weight:700;}
.notification-list{padding:13px 0; border-bottom:#DCE0EB solid 0.5px;}
.notification-list:first-child{padding-top:0;}
.notification-date{color:#a6a7ad; padding-top:6px;}
/**********panel css******************/
.panel-design .nav-tabs .nav-link{background:#303E4E; border-radius:5px 5px 0 0; margin:0 4px 0 0; border:none; color:#fff; font-size:15px; font-weight:600; text-decoration:underline;     height: 42px;    line-height: 42px; padding:0 16px;}
.panel-design .nav-tabs .nav-link.active{background:#4296F7; color: #fff !important; text-decoration:none;}
.panel-design .panel-content{border:#DCE0EB solid 1px;}
.panel-content .panel-head{background:#F5F6FA; height:70px; padding:12px 20px;}
.panel-content .panel-head h3{font-size:20px; color:#303E4E; font-weight:600;}
.form label{font-size:15px; font-weight:600; display:inline-block; margin:0 15px 6px 0;}
.form .manadatory{color:#DF4F3C;  float:left; padding:0 1px 0 0;}
.bottom-button{background:#303E4E; height:78px; padding:20px 20px;}
/******************table style*********************/
.table.table-style tr th{background:#415164; white-space: nowrap; border-bottom:none; color:#fff; font-size:14px; font-weight:700;}
.table.table-style tr:nth-child(even){background:#F5F6FA;}
.table.table-style.table-bordered td, .table.table-style.table-bordered th{border:#D1D3DB solid 1px;}
.table.table-style tr.serch-by-subject td{background:#303E4E;}
.serch-by input[type="text"]{background:#fff url('/assets/images/input-search-icon.svg') no-repeat 5px; width:100%; height:32px; padding:7px 7px 7px 22px; border:#4D4F5C solid 1px; border-radius:4px;}
.serch-by .submit{background:none; padding:0; border:none; position:absolute; left:8px; top:8px;}
.serch-by .submit .fa.fa-search{color:#4D4F5C;}
.pagination.style-1 .page-link{border:none; color:#4D4F5C; margin:0 6px 0 0; padding:0; height:31px; width:31px; line-height:30px; text-align:center;}
.pagination.style-1 .page-link:hover{background:none;}
.pagination.style-1 .page-link.page-no{border:#E6E7E8 solid 1px; border-radius:2px;}
.pagination.style-1 .page-link.pre, .pagination.style-1 .page-link.next{border-radius:2px 0 0 2px;}
.pagination.style-1 .page-link.pre, .pagination.style-1 .page-link.next {background:#F3F4F5;}
.heading-style-3{background:#3597EC; color:#fff;}
.listing-heading.md h4, .heading-style-3 h4{font-size:20px;   line-height:40px;   padding:0 12px; font-weight:400;  margin:0;}
.heading-style-3 h4{font-size:18px; font-weight:700;}
.info-detail .title {
  font-size: 16px;
  font-weight: 700;
  color: #303E4E;
}
.digit-box .title{font-size:16px; color:#4D4F5C; margin: 0;}
.digit-box .no-box{font-size:50px; color:#303E4E; font-weight:900; line-height:45px;}
.thumbnail-box{padding:15px 10px; min-height:125px;}
.blue-bg {
  background: #3497EC !important;
}
.serch-by .submit .fa.fa-search {
  color: #4D4F5C;
}
.serch-by .submit {
  background: none;
  padding: 0;
  border: none;
  position: absolute;
  left: 8px;
  top: 8px;
}
.table.table-style tr.serch-by-subject td, .table.table-style tr.serch-by td {
  background: #303E4E;
}


/**************************New Dashboard Style************************************/
/*nav tab*/
.tab-panel-wrap .nav.nav-tabs .nav-link{background:#303E4E; padding:0 16px; border:none; line-height:44px; color:#fff; text-decoration:underline; font-size:15px; font-weight:600; margin-right:5px; border-radius:5px 5px 0 0;}
.tab-panel-wrap .nav-tabs{border-bottom:none;}
.tab-panel-wrap .nav-tabs .nav-item.show .nav-link, .tab-panel-wrap .nav-tabs .nav-link.active, .tab-panel-wrap .nav-tabs .nav-link:hover{border:none; background:#F5F6FA; color:#525252; text-decoration:none;}
.tab-panel-wrap .nav-tabs .nav-item{margin-bottom:0;}
.panel-content .panel-heading{background:#F5F6FA; padding:35px 25px; margin-bottom:30px;}
.panel-heading h3{font-size:28px; font-weight:bold;}
.select-domain{margin-bottom:56px; max-width:400px;}
.select-domain label{font-size:19px; line-height:26px; display:inline-block; margin-bottom:10px;}
.select-domain label span{width: 8px;  float: left;}
.nav-tabs .nav-item{
  margin-top: 5px;
}

/****************Login CSS***********************/
.login-wrap{min-height: 100vh; width:400px; background:#fff;}
.login-right-wrap{background-repeat: no-repeat; background-position: center;   background-size: cover;   width: calc(100% - 400px);    position: relative;
    z-index: 1; background-image:url(../images/login-right-wrap-bg.svg);}
.signin-box .form-group label{font-weight:600; font-size:15px; margin-bottom:8px;}
.login-wrap .login-title{font-family: 'Roboto Slab', serif; font-size:28px; line-height:28px; color:#303E4E;}
.signin-box input.form-control.aud-input{color:#303E4E; font-size:16px;}
.login-right-title{font-family: 'Roboto Slab', serif; font-size: 47px; line-height:47px; color:#fff; margin:200px 0 0 90px;}
.eye-blue {
  background: url(../images/eye-blue.svg) no-repeat 0 0;
  min-width: 15px;
  height: 10px;
}
.blue-link-2 {
  font-size: 14px;
  color: #4A9AF7;
  text-decoration: none;
}

.chart-legend li{
  white-space: nowrap;
}

.chart-legend li span{
  width: 40px;
    height: 10px;
    float: left;
    margin-top: 8px;
    margin-right: 5px;
}
.chart-adcontact li span{
  width: 13px !important;
  height: 13px !important;
  float: left;
  margin-top: 6px !important;
  margin-right: 10px !important;
  border-radius: 50% !important;
}
.chart-static-width{
  width: 194px;
}
.strike{
  text-decoration: line-through !important;
}

.ticket-info .ticket-title a{font-size:26px; color:#303E4E; text-decoration:underline;}
.ticket-info .ticket-title a span{font-weight:600;}
.ticket-info .ticket-update{font-size:15px; color:#303E4E;}
.ticket-info .ticket-description h5{font-size:24px; font-weight:600; margin-bottom:20px;}
.ticket-info .ticket-description p{margin-bottom:20px; font-size:17px; line-height:24px;}
.ticket-info .ticket-update .update-time{font-size: 15px;    font-weight: 600; line-height:35px;}
.ticket-update .update-time .time{font-weight:400;}
.ticket-info .ticket-dtl{font-size:15px; line-height:20px;}
.ticket-info .ticket-dtl span{font-weight:700;}
.ticket-comment h4{font-weight:700; margin-bottom:10px; font-size:16px;}
.listing-border {
  border: #D1D3DB solid 1px !important;
}
.sm-content-padding {
  padding: 25px 12px !important;
}
.selectdiv {
  position: relative;
  border-radius: 4px;
  display: inline-block;
}
.selectdiv select {
  line-height: 30px;
  height: 30px;
  -webkit-appearance: none;
  border-radius: 4px;
  background: #fff;
  font-size: 13px;
  font-weight: 600;
  color: #4D4F5C;
  padding: 0 28px 0 10px;
}

.selectdiv:before, .selectdiv:after {
  width: 8px;
  height: 4px;
  position: absolute;
  right: 10px;
  content: "";
  background: url(/assets/images/sorting-arrow.svg) no-repeat 0 0;
}

.selectdiv:before {
  background-position: 0 0;
  top: 10px;
}

.selectdiv:after {
  background-position: 0 -5px;
  top: 18px;
}
/* review footer */
.review-submit {
  background: #323E4A url(../images/final-review-arow.png) no-repeat 32px bottom;
  min-height: 170px;
}
.white-text {
  color: #fff !important;
}

.ml-140 {
  margin-left: 140px !important;
}
.font-22 {
  font-size: 22px !important;
}

.flex-200 {
  flex-basis: 200px !important;
  min-width: 200px;
}
.cr-tab .listing-heading a.collapsed .drop-icon, .cr-tab .listing-heading .drop-icon { background: url(/assets/images/collapse-arrow.svg) no-repeat 0 0; width: 9px; height: 12px; display: inline-block; margin-left: 7px; transition: all 0.5s; transform: rotate(0deg); }
.cr-tab .listing-heading .drop-icon { width: 9px; height: 13px; transform: rotate(90deg); }
.cr-tab .listing-heading a{text-decoration: none;}
/********* review footer end************/
.cr-tab .panel-heading ul.page-navigation, .cr-tab .panel-heading h3{margin-bottom:0;}
.cr-tab .filter-by .date .select-aud-source{width:auto !important; background: url(/assets/images/down-arrow-icon.svg) no-repeat right 11px !important; }
/********* review footer end************/

/* .error-modal{max-width:270px;} */
.error-modal .modal-header{padding:0 20px; border-bottom:#D1D3DB solid 1px; background:#F8F8F8; border-radius:0;}
.error-modal .modal-header h4{font-size:20px; color:#303E4E; font-weight:700; height:48px; line-height:48px; }
.error-modal .close, .error-modal .close:hover{ color: #4D4F5C; font-size: 29px; opacity: 1; margin: 10px -5px 0 0; padding: 0; outline:none; }
.error-modal .error-body{padding:22px; text-align:center; border-radius:0;}
.error-modal .error-body .error-img{margin-bottom:15px;}
.error-modal .error-body .error-text{font-size:15px; line-height:22px;}
.error-modal .close{
  margin: 10px -5px 0 0 !important;
    padding: 0 !important;
}
.text-white{
  color: #fff;
}
.theme-bg {
  background: #303E4E !important;
}
.btn.aud-btn.theme-bg{color:#fff !important;}
.cr-tab .panel-content .filter-by label{margin-bottom:0;}

.img-box img{
  max-height: 84px;
  max-width: 84px;
}
.min-height-220{min-height:220px !important;}
/*******************disable icon**************************/
.disable-box .btn.aud-button{background:#718295 !important; color:#fff; opacity:1;}
.disable-box .list-content{font-size:32px; color:#B7B8BC; text-transform:uppercase;}
.disable-lock-icon{width:11px; height:14px; background:url(/assets/images/disable-lock-icon.svg) no-repeat 0 0; display:inline-block; margin:0 3px 0 0;}
.track-box.disable-box label{color:#4D4F5C;}
.cr-tab .track-box label, .cr-tab .total-count .track-box label { font-size: 20px; margin-bottom: 6px; line-height:24px; }
.cr-tab .btn.blue:hover{background:#4FA9F7 !important;}
/*******************disable icon**************************/
.theme-green .bs-datepicker-head, .theme-green .bs-datepicker-body table td span.selected {
  background-color: #46A99C !important;

 }

 .theme-green .bs-datepicker-body table td.week span{
  color: #46A99C !important;
 }

.chart-legend.reset-size .1-legend li{    display: flex; margin-bottom: 5px;
}
.chart-legend.reset-size .1-legend li span{ width: 75px;
    height: 18px;
    float: left;
    margin-top: 0;
    margin-right: 5px;}

    .listing .listing-heading h3{
      position: relative;
    }
    .heading-overlay{
      width: 100%;
    height: 55px;
    float: left;
    position: absolute;
    left: 0;
    display: none;
    top: 0;
    }


select.form-control.aud-input.style3, .que-select.reset-select .ngx-dropdown-button{background:url(/assets/images/gray-drop-down.svg) no-repeat;    background-position: 100% center; border:#EDEEF0 solid 1px !important; color:#303E4E; font-size:14px; font-weight:600;}

.notification-icon{display:inline-block; background-size: 100% !important; background-repeat:no-repeat !important; margin-right:12px; width:28px; height:28px;}
.notification-icon.billing{background:url(/assets/images/notifications-billing.svg);}
.notification-icon.news{background:url(/assets/images/notifications-news.svg);}
.notification-icon.intakes{background:url(/assets/images/notification-intake.svg);}
.notification-icon.products{background:url(/assets/images/notifications-products.svg);}
.notification-icon.global{background:url(/assets/images/notifications-global.svg);}
.notification-icon.urgency{background:url(/assets/images/notifications-urgency.svg);}
.notification-icon.date-specific{background:url(/assets/images/notifications-date-specific.svg);}

.ui-paginator-pages{
  height: 32px !important;
}
.account-manager-min-height{
  min-height: 204px;
}
.form-control.aud-input{
  resize: none !important;
}
.titleWithoutLink{
  display: none;
}
.titleWithLink{
  text-decoration: underline !important;
}
.iconWidthHeight img{
  vertical-align: text-top;
}

.page-link{
  text-decoration: none !important;
  color: #333 !important;
  font-weight: normal !important;
  font-size: 1rem;
}

.btn{
  box-shadow: none !important;
}

.social-wrap .listing-box li{
  background-image: url(/assets/images/ic_checked.svg);
    background-repeat: no-repeat;
    line-height: 20px;
    padding-left: 30px;
    background-position: 0 2px;
    margin-bottom: 5px;
}

.div-overlay{
  position:absolute; width:100%; height:100%; left:0px; top:0px;
}

.insight-edit-bg{
  background: #4296F7;
              width: 28px;
              height: 28px;
              position: absolute;
              right: 0;
              top: 0;
}
.today{
  background-color: #d1dae0 !important
}

/** Utility Classes */
.greyscale {
  filter: grayscale(1);
}


.filter-by .date label { cursor: pointer; }

.filter-by .select-aud-source { cursor: pointer; }
