.arrow-down {
  position: absolute;
  width: 0;
  height: 0;
  left: 8px;
  top: 4px;
  width:20px;
  height:19px;
}

.arrow-right {
  position: absolute;
  width: 0;
  height: 0;
  left: 8px;
  top: 4px;
  width:20px;
  height:19px;
}
