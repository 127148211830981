/* css lalit */

/*icons*/
.icon { display: inline-block; background-repeat: no-repeat; background-position: center center;  vertical-align:middle}
.icon.dashboard { background-image: url(../images/left-menu-icon/dashboard.svg); }
.icon.site-setting { background-image: url(../images/site-setting.svg); }
.icon.manage-card { background-image: url(../images/left-menu-icon/manage-cards.svg); }
.icon.my-products { background-image: url(../images/product-icon.svg); }
.icon.manage-websites { background-image: url(../images/manage-website-icon.svg); }
.icon.payment-history{background-image: url(../images/billing-icon.svg); }
.icon.billing{background-image: url(../images/left-menu-icon/billing.svg); }
.icon.service-tickets { background-image: url(../images/left-menu-icon/service-ticket.svg); }
.icon.intakes { background-image: url(../images/Intakes-icon.svg); }
.icon.new-business { background-image: url(../images/left-menu-icon/new-business.svg); }
/* .active > .icon.new-business { background-image: url(../images/opportunity-white.svg); } */
.icon.help { background-image: url(../images/help-icon.svg); }
.active > .icon.help { background-image: url(../images/help-icon-white.svg); }
.icon.reports { background-image: url(../images/reports-icon.svg); }
.active > .icon.reports { background-image: url(../images/reports-icon-white.svg); }
.icon.caret-down { background-image: url(../images/down-arrow-white.svg); }
.icon.tick-mark { background-image: url(../images/tick-mark-icon.svg); }
.icon.elilipses { background-image: url(../images/ellipses-icon.svg); }
.icon.incomplete { background-image: url(../images/incomplete-icon.svg); }
.icon.tickets { background-image: url(../images/ticket-icon.svg); }
.icon.projects { background-image: url(../images/left-menu-icon/projects.svg); }
.icon.completed-projects { background-image: url(../images/left-menu-icon/projects.svg); }
/* .active > .icon.projects { background-image: url(../images/project-icon-white.svg); } */
.icon.trash { background-image: url(../images/trash-icon.svg); }
.icon.location { background-image: url(../images/location.svg); }
.icon.picture { background-image: url(../images/picture.svg); }
.icon.star { background-image: url(../images/star.svg); }
.icon.star-yellow { background-image: url(../images/star-yellow.svg); }
.icon.attachment { background-image: url(../images/attachment.svg); }
.icon.attachment-white { background-image: url(../images/attachment-white.svg); }
.icon.play{background-image:url(/assets/images/SEMrush/fv.svg);}

.icon.eye{background-image:url(../images/eye-icon.svg);}
.icon.edit{background-image:url(../images/edit-icon.svg);}
.icon.edit-white{background-image:url(../images/ic_pencil.svg);}

.icon.cross{background-image:url(../images/cross-icon.svg);}
.icon.shift{background-image:url(../images/shift.svg); background-size: 80%; opacity: 0.5;}
.icon.removeUser{background-image:url(../images/ic_remove_user.svg); background-size: 80%;}
.icon.loading{background-image:url(../images/loading-arrows.svg); background-size: 80%; opacity: 0.5;}
.icon.download{background-image:url(../images/download-icon.svg); background-size: 80%; opacity: 0.5;}
.icon.export{background-image:url(../images/download-icon.svg); background-size: 80%; opacity: 0.5;}
.icon.print{background-image:url(../images/printer-icon.svg); background-size: 80%; opacity: 0.5;}

.icon.downgrade{background-image:url(../images/downgrade.png);}
.icon.services{background-image:url(../images/connect.svg);}
.icon.configuration{background-image:url(../images/configuration.svg);}
.icon.connect{background-image:url(../images/left-menu-icon/connects.svg);}
/* .active > .icon.connect{background-image:url(../images/connect-white.svg);} */
.icon.cp{ background-image: url(../images/cp-icon.svg); }
.icon.master{background-image:url(../images/master-icon.svg);}
.icon.page-collection{background-image:url(../images/page-collection.svg);}
.icon.proposal{background-image:url(../images/proposal.svg);}
.icon.users{background-image:url(../images/left-menu-icon/manage-user.svg);}
.icon.third-party-accounts{background-image:url(../images/left-menu-icon/ic_download.svg);}
.icon.sow-tac{background-image:url(../images/left-menu-icon/ic_privacy.svg);}
.icon.manage-user{background-image:url(../images/left-menu-icon/manage-user.svg);}
.icon.manage-programs{background-image:url(../images/left-menu-icon/manage-cps.svg);}
/* .active > .icon.manage-cp{background-image:url(../images/corporate-partner-white.svg);} */
.icon.manage-customer{background-image:url(../images/user-icon.svg);}
.icon.manage-clients{background-image:url(../images/left-menu-icon/manage-site.svg);}
.table-action-icon.visit-site{background-image:url(../images/manage-site-blk.svg);}
/* .active > .icon.manage-clients{background-image:url(../images/manage-website-icon-white.svg);} */
.icon.manage-sites{background-image:url(../images/left-menu-icon/manage-site.svg);}
/* .active > .icon.manage-sites{background-image:url(../images/manage-website-icon-white.svg);} */
.icon.my-account{background-image:url(../images/account.svg);}
.icon.services{background-image:url(../images/services-icon.svg);}
.icon.audits-proposals{background-image:url(../images/left-menu-icon/audit-proposals.svg);}
.icon.content-generation{background-image:url(../images/left-menu-icon/audit-proposals.svg);}
.icon.img-thumb{background-image:url(../images/img-thumb-small.svg);}
.icon.notification{background-image:url(../images/notification-icon.svg);}
.active .icon.notification{background-image:url(../images/notification-icon-white.svg);}
.icon.jira{background-image:url(../images/jira.svg);}
.icon.cancellations { background-image: url(../images/left-menu-icon/cancellations.svg); }
.icon.initiate { background-image: url(../images/initiate.png); }
.icon.utility{background-image:url(../images/left-menu-icon/log.svg);}
.icon.upload{ background:url(../images/file-uploader-icon.svg);}
/*user auth arow css*/


/*input fields*/
.form-control.aud-input { background-color: #fff;  border-radius: 0px; color: #ADADAD; border:#bababa solid 1px; }
.form-control.aud-input:focus { box-shadow: none; outline:none;  }
.custom-file-input:focus~.custom-file-label{ box-shadow: none; outline:none;  border: 1px solid #BABABA; }
/*input sm*/
.form-control.aud-input.form-control-sm { height: 30px; padding: 2px 8px; font-size: 13px; border-radius:0px;}
/*input md*/
.form-control.aud-input.form-control-md { height: 38px; padding: 5px 8px; font-size: 13px; border-radius:0px; }
/*input lg*/
.form-control.aud-input.form-control-lg { height: 46px; padding: 5px 8px; font-size: 15px; border-radius:0px; }

/*input box*/
.form-control.aud-input::-webkit-input-placeholder {
color: #4A4C57;
}
.form-control.aud-input::-moz-placeholder {
color: #4A4C57;
}
.form-control.aud-input:-ms-input-placeholder {
color: #4A4C57;
}
.form-control.aud-input:-moz-placeholder {
color: #4A4C57;
}
.form-control.aud-input::placeholder {
    color: #4A4C57 !important;
    }
/*disable*/
/*custom file upload*/
/*File Browse sm set*/
.form-control-xs.form-control.aud-input.custom-file-input{height: 30px; padding: 5px 8px; font-size: 13px; border-radius: 0px;}
.custom-file .custom-file-sm.custom-file-label{height: 30px; padding: 3px 8px; font-size: 13px; border-radius: 0px;  color:#ADADAD; line-height:23px;}
.custom-file .custom-file-sm.custom-file-label:after{width:30px; height:29px; background-size:100%;}
/*File Browse md set*/
.form-control-sm.form-control.aud-input.custom-file-input{height: 37px; padding: 5px 8px; font-size: 13px; border-radius: 0px;}
.custom-file .custom-file-md.custom-file-label{height: 35px; line-height:28px; padding: 3px 8px; font-size: 13px; border-radius: 0px;  color:#ADADAD;}
.custom-file .custom-file-md.custom-file-label:after{width:35px; height:34px; background-size:100%;}
.custom-file{height:auto;}
.custom-file .custom-file-lg.custom-file-label, .form .custom-file .form-control-lg.custom-file-input{height: 46px;padding: 5px 10px;font-size: 14px; line-height:38px; color:#BABABA;cursor:pointer; border-radius:0;}
.custom-file .custom-file-lg.custom-file-label{margin-bottom:0;}
.custom-file .custom-file-label:after{height:46px; width:47px; font-size:0; cursor:pointer; background:url(../images/file-uploader-icon.svg) no-repeat 0 0; border-radius:0; top:-1px; right:0; background-size:100%;}

.form-control.aud-input[readonly]{background:#f6f6f6;}

/*radius class*/
.form-control.aud-input.has-radius{ border-radius:4px !important;}

/*global color for input box*/
.form-control.aud-input::placeholder{color:#7b7676;}
.form-control.aud-input{color:#4a4c57}
/*select option color change*/
select.form-control.aud-input{color:#4a4c57;}
select.form-control.aud-input option:first-child{color:#ADADAD;}
select.form-control.aud-input option{color:#ADADAD;}
select.form-control.aud-input-option option{color:#000;}
/*radius class for input style 2*/
/*radius for custom style 2*/
.form-control.style2.aud-input+.custom-file-label{border-radius:4px; border:1px solid #e4e4e4;}
.form-control.style2.aud-input+.custom-file-label:after{border-radius:0 4px 4px 0;}
/*input style2*/
.form-control.style2.aud-input::-webkit-input-placeholder {
color: #CECFD0;
}
.form-control.style2.aud-input::-moz-placeholder {
color: #CECFD0;
}
.form-control.style2.aud-input:-ms-input-placeholder {
color: #CECFD0;
}
.form-control.style2.aud-input:-moz-placeholder {
color: #CECFD0;
}
/*input style 2*/
.form-control.style2.aud-input{ border:1px solid #E8E9EC; border-radius:4px; color:#43425D;}
/*select 2 color 2*/
select.form-control.style2.aud-input {
    color: #ADADAD;
}
/*.form-control.style2.form-control-sm{ }
.form-control.style2.form-control-md{ }
.form-control.style2.form-control-lg{ }*/
/*select box*/
select.form-control.aud-input::-ms-expand {
display: none;
}
select.form-control.aud-input { -webkit-appearance: none; -moz-appearance: none; text-indent: 1px; text-overflow: ''; background-image: url(../images/select-drop-arrow.svg); background-repeat: no-repeat; background-position: 100% center; }
/*btn*/
.btn.aud-btn { border-radius: 4px; }
.btn.aud-btn:hover, .btn.aud-btn:focus, .btn.aud-btn:active {box-shadow: none; }
.btn.aud-btn:not(:disabled):not(.disabled):active { box-shadow: none }

.btn.aud-btn.dark-gray { background-color: #4D4F5C; border: 1px solid #4D4F5C; color: #fff; }
.btn.aud-btn.dark-gray:hover .btn.aud-btn.dark-gray:focus, .btn.aud-btn.dark-gray:active{ background-color: #4D4F5C;}

.btn.aud-btn.orange { background-color: #DF4F3C; border: 1px solid #DF4F3C; color: #fff; }
.btn.aud-btn.orange:hover, .btn.aud-btn.orange:active, .btn.aud-btn.orange:focus { background-color: #F46553; border: 1px solid #F46553;}

.btn.aud-btn.green{background:#03A99B; border: 1px solid #03A99B; color: #fff;}
.btn.aud-btn.green:hover, .btn.aud-btn.green:active, .btn.aud-btn.green:focus  { background-color: #03A99B;}

.btn.aud-btn.white { background-color: #fff; border: 1px solid #D3D3D3; color: #ADADAD; }
.btn.aud-btn.white:hover, .btn.aud-btn.white:active, .btn.aud-btn.white:focus  { background-color: #fff;}
/*medium*/
.btn.btn-md { height: 38px; font-size: 15px; min-width: 74px; padding: 6px 20px; }


/* custom checkox */
.checkbox-container { position: relative; padding-left: 39px; margin-bottom: 12px; cursor: pointer; font-size: 17px; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; display: inline-block; margin-right: 14px; line-height: 30px; color: #43425D; }
/* Hide the browser's default checkbox */
.checkbox-container input { position: absolute; opacity: 0; cursor: pointer; height: 0; width: 0; }
/* Create a custom checkbox */
.checkmark { position: absolute; top: 0; left: 0; height: 28px; width: 28px; border-width: 2px; border-color: #bababa; border-style: solid; display: inline-block; background: #fff; }
/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark { background-color: #fff; border: #3597ec solid 2px; }
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after { content: ""; position: absolute; display: none; }
/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after { display: block; }
/* Style the checkmark/indicator */
.checkbox-container .checkmark:after { left: 8px; top: 4px; width: 8px; height: 12px; border-style: solid; border-color: #3597ec; border-width: 0 3px 3px 0; -webkit-transform: rotate(48deg); -ms-transform: rotate(48deg); transform: rotate(48deg); }


/*single*/
.checkbox-container.single{ margin-right:0px;}

/*checkbox sizes sm set*/
.checkbox-container.sm {  height:18px; line-height:18px; padding-left:28px; font-size:14px;}
.checkbox-container.sm .checkmark{width:18px; height:18px; border-radius:3px;}
.checkbox-container.sm .checkmark:after{left: 4px; top: 1px; width: 6px; height: 9px;}

/*checkbox sizes lg set*/
.checkbox-container.md {  height:22px; line-height:22px; padding-left:33px; font-size:15px;}
.checkbox-container.md .checkmark{width:22px; height:22px; border-radius:3px;}
.checkbox-container.md .checkmark:after{left: 5px; top: 2px; width: 7px; height: 11px;}

/*checkbox sizes md set*/
.checkbox-container.lg .checkmark { height: 28px; width: 28px; border-width: 2px; border-radius: 6px; }
.checkbox-container.lg .checkmark:after { left: 8px; top: 4px; width: 8px; height: 12px; border-width: 0 3px 3px 0; -webkit-transform: rotate(48deg); -ms-transform: rotate(48deg); transform: rotate(48deg); }




/*checkbox sizes lg set*/


/* margin classes */
.mr-1 { margin-right: 1px; }
.ml-1 { margin-left: 1px; }
.mr-2 { margin-right: 2px; }
.ml-2 { margin-left: 2px; }
.mr-3 { margin-right: 3px; }
.ml-3 { margin-left: 3px; }
.mr-4 { margin-right: 4px; }
.ml-4 { margin-left: 4px; }
.mr-5 { margin-right: 5px; }
.ml-5 { margin-left: 5px; }
.mr-6 { margin-right: 6px; }
.ml-6 { margin-left: 6px; }
.mr-7 { margin-right: 7px; }
.ml-7 { margin-left: 7px; }
.mr-8 { margin-right: 8px; }
.ml-8 { margin-left: 8px; }
.mr-9 { margin-right: 9px; }
.ml-9 { margin-left: 9px; }
.mr-10 { margin-right: 10px; }
.ml-10 { margin-left: 10px; }
.mr-11 { margin-right: 11px; }
.ml-11 { margin-left: 11px; }
.mr-12 { margin-right: 12px; }
.ml-12 { margin-left: 12px; }
.mr-13 { margin-right: 13px; }
.ml-13 { margin-left: 13px; }
.mr-14 { margin-right: 14px; }
.ml-14 { margin-left: 14px; }
.mr-15 { margin-right: 15px; }
.ml-15 { margin-left: 15px; }
.mr-16 { margin-right: 16px; }
.ml-16 { margin-left: 16px; }
.mr-17 { margin-right: 17px; }
.ml-17 { margin-left: 17px; }
.mr-18 { margin-right: 18px; }
.ml-18 { margin-left: 18px; }
.mr-19 { margin-right: 19px; }
.ml-19 { margin-left: 19px; }
.mr-20 { margin-right: 20px; }
.ml-20 { margin-left: 20px; }
.mr-21 { margin-right: 21px; }
.ml-21 { margin-left: 21px; }
.mr-22 { margin-right: 22px; }
.ml-22 { margin-left: 22px; }
.mr-23 { margin-right: 23px; }
.ml-23 { margin-left: 23px; }
.mr-24 { margin-right: 24px; }
.ml-24 { margin-left: 24px; }
.mr-25 { margin-right: 25px; }
.ml-25 { margin-left: 25px; }
.mr-26 { margin-right: 26px; }
.ml-26 { margin-left: 26px; }
.mr-27 { margin-right: 27px; }
.ml-27 { margin-left: 27px; }
.mr-28 { margin-right: 28px; }
.ml-28 { margin-left: 28px; }
.mr-29 { margin-right: 29px; }
.ml-29 { margin-left: 29px; }
.mr-30 { margin-right: 30px; }
.ml-30 { margin-left: 30px; }
.mr-31 { margin-right: 31px; }
.ml-31 { margin-left: 31px; }
.mr-32 { margin-right: 32px; }
.ml-32 { margin-left: 32px; }
.mr-33 { margin-right: 33px; }
.ml-33 { margin-left: 33px; }
.mr-34 { margin-right: 34px; }
.ml-34 { margin-left: 34px; }
.mr-35 { margin-right: 35px; }
.ml-35 { margin-left: 35px; }
.mr-36 { margin-right: 36px; }
.ml-36 { margin-left: 36px; }
.mr-37 { margin-right: 37px; }
.ml-37 { margin-left: 37px; }
.mr-38 { margin-right: 38px; }
.ml-38 { margin-left: 38px; }
.mr-39 { margin-right: 39px; }
.ml-39 { margin-left: 39px; }
.mr-40 { margin-right: 40px; }
.ml-40 { margin-left: 40px; }
.mr-41 { margin-right: 41px; }
.ml-41 { margin-left: 41px; }
.mr-42 { margin-right: 42px; }
.ml-42 { margin-left: 42px; }
.mr-43 { margin-right: 43px; }
.ml-43 { margin-left: 43px; }
.mr-44 { margin-right: 44px; }
.ml-44 { margin-left: 44px; }
.mr-45 { margin-right: 45px; }
.ml-45 { margin-left: 45px; }
.mr-46 { margin-right: 46px; }
.ml-46 { margin-left: 46px; }
.mr-47 { margin-right: 47px; }
.ml-47 { margin-left: 47px; }
.mr-48 { margin-right: 48px; }
.ml-48 { margin-left: 48px; }
.mr-49 { margin-right: 49px; }
.ml-49 { margin-left: 49px; }
.mr-50 { margin-right: 50px; }
.ml-50 { margin-left: 50px; }
/* margin classes */
.pr-1 { padding-right: 1px; }
.pl-1 { padding-left: 1px; }
.pr-2 { padding-right: 2px; }
.pl-2 { padding-left: 2px; }
.pr-3 { padding-right: 3px; }
.pl-3 { padding-left: 3px; }
.pr-4 { padding-right: 4px; }
.pl-4 { padding-left: 4px; }
.pr-5 { padding-right: 5px; }
.pl-5 { padding-left: 5px; }
.pr-6 { padding-right: 6px; }
.pl-6 { padding-left: 6px; }
.pr-7 { padding-right: 7px; }
.pl-7 { padding-left: 7px; }
.pr-8 { padding-right: 8px; }
.pl-8 { padding-left: 8px; }
.pr-9 { padding-right: 9px; }
.pl-9 { padding-left: 9px; }
.pr-10 { padding-right: 10px; }
.pl-10 { padding-left: 10px; }
.pr-11 { padding-right: 11px; }
.pl-11 { padding-left: 11px; }
.pr-12 { padding-right: 12px; }
.pl-12 { padding-left: 12px; }
.pr-13 { padding-right: 13px; }
.pl-13 { padding-left: 13px; }
.pr-14 { padding-right: 14px; }
.pl-14 { padding-left: 14px; }
.pr-15 { padding-right: 15px; }
.pl-15 { padding-left: 15px; }
.pr-16 { padding-right: 16px; }
.pl-16 { padding-left: 16px; }
.pr-17 { padding-right: 17px; }
.pl-17 { padding-left: 17px; }
.pr-18 { padding-right: 18px; }
.pl-18 { padding-left: 18px; }
.pr-19 { padding-right: 19px; }
.pl-19 { padding-left: 19px; }
.pr-20 { padding-right: 20px; }
.pl-20 { padding-left: 20px; }
.pr-21 { padding-right: 21px; }
.pl-21 { padding-left: 21px; }
.pr-22 { padding-right: 22px; }
.pl-22 { padding-left: 22px; }
.pr-23 { padding-right: 23px; }
.pl-23 { padding-left: 23px; }
.pr-24 { padding-right: 24px; }
.pl-24 { padding-left: 24px; }
.pr-25 { padding-right: 25px; }
.pl-25 { padding-left: 25px; }
.pr-26 { padding-right: 26px; }
.pl-26 { padding-left: 26px; }
.pr-27 { padding-right: 27px; }
.pl-27 { padding-left: 27px; }
.pr-28 { padding-right: 28px; }
.pl-28 { padding-left: 28px; }
.pr-29 { padding-right: 29px; }
.pl-29 { padding-left: 29px; }
.pr-30 { padding-right: 30px; }
.pl-30 { padding-left: 30px; }
.pr-31 { padding-right: 31px; }
.pl-31 { padding-left: 31px; }
.pr-32 { padding-right: 32px; }
.pl-32 { padding-left: 32px; }
.pr-33 { padding-right: 33px; }
.pl-33 { padding-left: 33px; }
.pr-34 { padding-right: 34px; }
.pl-34 { padding-left: 34px; }
.pr-35 { padding-right: 35px; }
.pl-35 { padding-left: 35px; }
.pr-36 { padding-right: 36px; }
.pl-36 { padding-left: 36px; }
.pr-37 { padding-right: 37px; }
.pl-37 { padding-left: 37px; }
.pr-38 { padding-right: 38px; }
.pl-38 { padding-left: 38px; }
.pr-39 { padding-right: 39px; }
.pl-39 { padding-left: 39px; }
.pr-40 { padding-right: 40px; }
.pl-40 { padding-left: 40px; }
.pr-41 { padding-right: 41px; }
.pl-41 { padding-left: 41px; }
.pr-42 { padding-right: 42px; }
.pl-42 { padding-left: 42px; }
.pr-43 { padding-right: 43px; }
.pl-43 { padding-left: 43px; }
.pr-44 { padding-right: 44px; }
.pl-44 { padding-left: 44px; }
.pr-45 { padding-right: 45px; }
.pl-45 { padding-left: 45px; }
.pr-46 { padding-right: 46px; }
.pl-46 { padding-left: 46px; }
.pr-47 { padding-right: 47px; }
.pl-47 { padding-left: 47px; }
.pr-48 { padding-right: 48px; }
.pl-48 { padding-left: 48px; }
.pr-49 { padding-right: 49px; }
.pl-49 { padding-left: 49px; }
.pr-50 { padding-right: 50px; }
.pl-50 { padding-left: 50px; }
.text-red { color: #DF4F3C }
.pt-12 { padding-top: 12px; }
.pb-12 { padding-bottom: 12px; }



.py-20 { padding-bottom: 20px;padding-top: 20px;  }
.py-30{ padding-bottom: 30px;padding-top: 30px;  }
/*active cases*/
.indicator.circle { background: #ECECEC; width: 22px; height: 22px; border-radius: 22px; vertical-align: middle; display: inline-block; margin: 0 10px 0 0; }
.indicator.circle.md{ width: 17px; height: 17px; }
.active .disabled .indicator.circle, .disabled .indicator.circle  { background: #ECECEC; }
.active .indicator.circle  { background: #3597EC url(../images/ellipses-icon.svg) no-repeat center; background-size: 50%; }
.incomplete .indicator.circle { background: #F7941E url(../images/incomplete-icon.svg) no-repeat center; background-size: 14%; }
.completed .indicator.circle { background: #00A89B url(/assets/images/tick-white-mark-icon.svg) no-repeat center; background-size: 50%; }



.blue-link{ color:#3B86FF; font-size:15px; text-decoration:underline;}
.blue-link:hover, .blue-link:active, .blue-link:focus{color:#3B86FF;}


/* font sizes*/
.font-13{font-size:13px; color:#3C414B;}
.font-14{font-size:14px;}
.font-15{font-size:15px; color:#43425D;}
.font-16{font-size:16px;}
.font-17{font-size:17px;}

.semi-bold{font-weight:600;}

/* opacity*/
.opacity-60{opacity:0.6;}

/* position */
.relative{position:relative;}
.absolute{position:absolute;}
.fixed{position:fixed;}

/* textarea fix */
textarea.form-control{resize:none;}



/*----------------------------------------------- Client Dashboard -----------------------------------------------*/

/*btn*/
.btn.aud-btn.no-radius{ border-radius:0px;}
.btn.purple{background:#43425D; color:#fff; border-color:#43425D;}
.btn.purple:hover, .btn.purple:active, .btn.purple:focus{ background:#43425D; color:#fff;}
.btn.aud-btn.blue{background:#3597EC; color:#fff; border-color:#3597EC;}
.btn.aud-btn.blue:hover, .btn.aud-btn.blue:active, .btn.aud-btn.blue:focus{background-color:#4FA9F7;}
.btn.aud-btn.sky-blue{background:#3597EC; border-color:#3597EC; color:#fff;}
.btn.aud-btn.sky-blue:hover, .btn.aud-btn.sky-blue:active, .btn.aud-btn.sky-blue:focus{background-color:#4FA9F7;  border-color:#4FA9F7;}
.btn.aud-btn.sky-blue.disabled{background:#A3A0FB; border-color:#A3A0FB; color:#fff; opacity:10;}
.btn.aud-btn.sky-blue:hover{color:#ffffff;}
.btn.aud-btn.blank{background:none; border:#D3D3D3 solid 1px; color:#D3D3D3;}
.btn.aud-btn.blank:hover{background:#4B5967;}
/*btn sm*/
.btn.btn-sm { height: 38px; font-size: 12px; min-width: 74px; padding: 0px 17px; line-height:26px; }
 /*btn lg*/
.btn.btn-lg{height: 46px; font-size: 18px; padding:11px 20px; border:none; border-radius:4px;}
 .icon.circle-add-user-icon { background-image: url(../images/circle-add-user-icon.svg); }
.icon.circle-advertising-icon{ background-image: url(../images/circle-advertising-icon.svg); }
.icon.circle-lock-icon { background-image: url(../images/circle-lock-icon.svg); }
.icon.lock-icon { background-image: url(../images/lock.svg); }
.icon.circle-message-icon { background-image: url(../images/circle-message-icon.svg); }
.icon.information { background-image: url(../images/information.svg);}
.pa-20{padding:20px;}
.max-946{max-width:946px;}
.min-946{min-width:946px;}
.max-458{max-width:458px;}
/*****************modal css*************************/
.modal-window .modal-content .nav.nav-tabs{margin-bottom:20px;}
.modal-window .modal-content .inside-box{margin-right:15px;}
.table.aud-table td, .table.aud-table th { padding:10px; border-bottom: #D3D3D3 solid 1px; border-top:0px; font-size:14px;}
.table.aud-table th { background: #F5F6FA; color:#4D4F5C; font-weight:700; }
.table.aud-table td { color:#43425D; position: relative; border-bottom:#D3D3D3 solid 1px; }
.table.aud-table td.action-btns .icon{ height:19px; width:19px; margin:0 2px; }
.width-200 {width: 200px !important;}

/*****************dashboard icon*************************/
.dashbord-icon{  align-self: center;  margin-bottom: 10px;    width: 60px;   height: 60px; background-repeat:no-repeat;}
.dashbord-icon.project-process{background-image:url(/assets/images/projects-dashboard-icon.svg);}
.dashbord-icon.opportunity{background-image:url(/assets/images/opportunity-dashboard-icon.svg);}
.dashbord-icon.clients, .dashbord-icon.client{background-image:url(/assets/images/clients-dashboard-icon.svg);}
.dashbord-icon.completed-project{background-image:url(/assets/images/completed-project-dashboard-icon.svg);}
.dashbord-icon.active-intake{background-image:url(/assets/images/active-intake-dashoard-icon.svg);}
.dashbord-icon.team{background-image:url(/assets/images/icon-qld.png);}
.dashbord-icon.chart{background-image:url(/assets/images/icon-amrpc.png);}
.dashbord-icon.marker{background-image:url(/assets/images/icon-location.png);}
.dashbord-icon.customers{background-image:url(/assets/images/customers-icon.svg);}
.dashbord-icon.audit{background-image:url(/assets/images/audit-icon.svg);}
.dashbord-icon.invoice{background-image:url(/assets/images/invoice-icon.svg);}
.dashbord-icon.progres{background-image:url(/assets/images/progress-icon.svg);}
.dashbord-icon.design{background-image:url(/assets/images/design-icon.svg);}
.dashbord-icon.development{background-image:url(/assets/images/development-icon.svg);}
.dashbord-icon.review{background-image:url(/assets/images/review-icon.svg);}
.dashbord-icon.failed-payment{background-image:url(/assets/images/payment-cancel-icon.svg);}
.dashbord-icon.new-customer{background-image:url(/assets/images/new-customer-icon.svg);}
.dashbord-icon.active-customer{background-image:url(/assets/images/active-customer-icon.svg);}
.dashbord-icon.premiun-customer{background-image:url(/assets/images/premium-customer-icon.svg);}
/*****************dashboard icon*************************/




a.play-button{background:url(/assets/images/play-icon.svg) no-repeat 0 0; width:27px; height:27px; display: inline-block;}
.web-icon{background:url(/assets/images/web-icon.svg) no-repeat 0 0; width:12px; height:12px; display: inline-block; margin:0 0 0 5px; }
.tick-icon{background:url(/assets/images/tick-icon.png) no-repeat 0 0; width:22px; height:22px; display: inline-block; margin:0 5px; min-width:22px; }
.share-icon{background:url(/assets/images/share-icon.png) no-repeat 0 0; width:24px; height:23px; display: inline-block; margin:0 5px; min-width:24px; }
.double-dash{background:url(/assets/images/double-dash.png) no-repeat 0 0; width:30px; height:7px; display: inline-block; margin:0 5px; }
.locate-icon{background:url(/assets/images/location-icon.svg) no-repeat 0 0; width:17px; height:22px; display: inline-block; margin: 3px 8px 0 0; }

.greean-bg{background:#16B5A6;}
.red-bg{background:#DF4F3D;}
.orange-bg-bar{background:#F6931E;}
.light-gray-bg{background:#DFDFDF;}
.drak-gray-bg{background:#939393;}
.purple-bg{background:#AF64DB !important;}
.font-600{font-weight:600 !important;}
.no-radius{border-radius:0 !important;}

.font-900{font-weight:900;}
.font-700{font-weight:700;}
.font-16{font-size:16px;}
.flex-basis-hlaf{flex-basis:49% !important; max-width:49%;}
.flex-basis-one-fourth{flex-basis:24% !important;}
.width-500{width:500px;}
.width-half{width:49%;}
.flex-basis-one-third{flex-basis:32% !important; max-width:32%;}
.btn.aud-button{min-width:100px; height:38px; color:#fff; border-radius:4px;}
.btn.aud-button.sm{height:25px; padding:0 12px; min-width:auto; line-height:23px;}
.blue{background:#3597EC;}
.mt-40{margin-top:40px !important;}
.flex-80{flex-basis:80px;}
.flex-150{flex-basis:150px;}
.dark-shade{background:#4D4F5C !important;}
.width-150{width:150px !important;}
.width-200{width:200px !important;}
.orange-color{background:#F6921E !important;}
.font-20{font-size:20px !important;}
.font-32{font-size:32px !important;}
.theme-color-bg{background:#303E4E !important;}
.min-height-302{min-height:302px !important;}
.dark-gray-bg{background:#D1D2DB !important;}
.blue-text{color:#303E4E !important;}
.light-gray-bg{background:#F7F7F7 !important;}
.gray-text{color:#B7B8BC !important;}
.error{background:#DF4F3D !important;}
.warning{background:#F6931E !important;}
.notice{background:#38A0ED !important;}
.min-heigh-auto{min-height:auto !important;}
.btn.aud-button.sm {
    height: 25px;
    padding: 0 12px;
    min-width: auto;
    line-height: 23px;
}

.btn.aud-button {
    min-width: 100px;
    height: 38px;
    color: #fff;
    border-radius: 4px;
}
.blue {
    background: #3597EC !important;
}

.theme-color-bg {
    background: #303E4E !important;
}

.font-32 {
    font-size: 32px !important;
}

.orange-color {
    background: #F6921E !important;
}

@media only screen and (min-width:1024px){
	.container{max-width:100%; padding:0;}
	.flex-920{flex-grow:1; margin-right:22px;}

}
@media only screen and (min-width:1230px){
	.container{max-width:1232px !important; padding:0;}
	.flex-920{flex-basis:920px !important; min-width:920px !important;}

}

.btn.add-btn.style-2 {
    border: #D1D2DB solid 2px;
    line-height: normal;
}

.btn.add-btn.style-2:hover{
    background: #415164 !important;
}
.btn.btn-sm {
    line-height: 38px !important;
    padding: 0 14px;
    font-size: 15px;
    border-radius: 4px;
    min-width: 70px;
}

.green-bg {
    background: #00A89B !important;
}

.listing .btn.add-btn.btn-sm {
    color: #fff;
     height: 38px;
}
.ticket {
    background: url(/assets/images/ticket-icon2.svg) no-repeat 0 0;
}

.img-website{
    background: url('/assets/images/website.svg') no-repeat #3497EC center center;
}

.img-redesign{
    background: url('/assets/images/redesign.png') no-repeat #3497EC center center; background-size: 50%;
}

.img-pay-per-click{
    background: url('/assets/images/pay-per-click.svg') no-repeat #DF4F3C  center center;
}
.img-facebook-ads{
    background: url('/assets/images/facebook-advertising.svg') no-repeat #303E4E  center center;
}
.img-social-media-marketing{
    background: url('/assets/images/socail-sharing.svg') no-repeat #F79520  center center;
}
.img-social-media-setup{
    background: url('/assets/images/sms.png') no-repeat #3497EC center center; background-size: 35%;
}
.img-social-media-design{
    background: url('/assets/images/social-media-icon.svg') no-repeat #3497EC center center; background-size: 35%;
}
.img-review-gen{
    background: url('/assets/images/review-gen.svg') no-repeat #00A89B center center;
}
.img-call-tracking{
    background: url('/assets/images/call-tracking.svg') no-repeat #3497EC center center;
}
.img-email{
    background: url('/assets/images/email.svg') no-repeat #3497EC center center;
}
.img-domain{
    background: url('/assets/images/domain.png') no-repeat #3497EC center center; background-size: 40%;
}
.img-citation-clean-up{
    background: url('/assets/images/citation.png') no-repeat #3497EC center center; background-size: 40%;
}
.img-logo{
    background: url('/assets/images/logo-symbol.png') no-repeat #3497EC center center; background-size: 35%;
}

.btn.add-btn.style-3 {
    text-decoration: underline;
    color: #fff;
    border-radius: 0;
    line-height: 35px;
}
.link-style-4 {
    font-weight: 600 !important;
    font-size: 14px;
    text-transform: uppercase;
}
.new {
    background: url(/assets/images/new.svg) no-repeat 0 0;
    height: 26px;
    width: 26px;
}
.view-all {
    background: url(/assets/images/view-all.svg) no-repeat 0 0;
    height: 26px;
    width: 26px;
}
.orange-bg {
    background: #DF4F3C !important;
}
.btn.btn-xl {
    line-height: 50px;
    height: 50px;
    padding: 0 14px;
    font-size: 15px;
    font-weight: 700;
    border-radius: 4px;
    min-width: 100px;
}

.heading-style-4{background:#EAEBF0; padding:0 12px; border-bottom:#D1D3DB solid 1px;}
.heading-style-5{background:#F5F6FA; padding:0 20px; border-bottom:#D3D3D3 solid 1px;}
.listing-heading.xs h2{font-size:26px;   line-height:54px; height:57px;   font-weight:400; padding:0 20px; margin:0;}
.listing-heading.sm h3, .heading-style-4 h3{font-size:18px;   line-height:35px;   padding:0 12px; font-weight:400;  margin:0;}
.listing-heading.md h4, .heading-style-3 h4{font-size:20px;   line-height:40px;   padding:0 12px; font-weight:400;  margin:0;}
.heading-style-5 h3{color:#4D4F5C; font-size:19px; line-height:46px; font-weight:700;}
.heading-style-3 h4{font-size:18px; font-weight:700;}
.heading-style-4 h3{color:#303E4E; font-size:15px; font-weight:600; padding:0;}
.flex-one-half {
    flex-basis: 49%;
}
.light-color {
    color: #737D88 !important;
}

.file-info a{
    height: 18px;
}

.icon.delete {
    background: url(/assets/images/delete.png) no-repeat 0 0;
    min-width: 13px;
    height: 14px;
}
.custom-file .custom-file-input+.custom-file-label{margin-right:0 !important;}
.btn.aud-button:hover{color:#fff;}

/****************table action item*************************/
.table-action-icon{display:inline-block; background-repeat:no-repeat !important; margin-right:6px; width:18px; height:18px;
    top: 8px;
    position: relative;
    background-size: 100% !important;}
.table-action-icon.impersonate{background:url(/assets/images/table-impersonate.svg);}
.table-action-icon.addUser{background:url(/assets/images/ic_add_user.svg);}
.table-action-icon.cancel-subscription{background:url(/assets/images/table-cancel-subscription.svg);}
.table-action-icon.site-config{background:url(/assets/images/table-site-config.svg);}
.table-action-icon.jira{background:url(/assets/images/table-jira.svg);}
.table-action-icon.jira-icon{background:url(/assets/images/jira-icon.svg);}
.table-action-icon.delete{background:url(/assets/images/table-delete.svg);}
.table-action-icon.edit{background:url(/assets/images/table-edit.svg); background-size: 80% !important;}
.table-action-icon.view-task{background:url(/assets/images/table-view-task.svg);}
.table-action-icon.start-intake{background:url(/assets/images/table-start-intake.svg);}
.table-action-icon.info{background:url(/assets/images/table-info.svg);}
.table-action-icon.wp-info{background:url(/assets/images/table-wp.svg);}
.table-action-icon.default{background:url(/assets/images/table-default.svg);}
.table-action-icon.billing-invoice{background:url(/assets/images/table-billing-invoice.svg);}
.table-action-icon.billing-blk{background:url(/assets/images/billing-blk.svg);}
.table-action-icon.reports-blk{background:url(/assets/images/reports-blk.svg);}
.table-action-icon.password-reset{background:url(/assets/images/password-reset.svg);}
